var myHeaders = new Headers();
const discordWebhook =
    "https://discord.com/api/webhooks/959505405208330300/dfbm41f0VJMbUVsYo8awRQdJWIMWZVYAArAYVHCQH9PB0Cuu-w1rEkWWyqPsIavZdhpJ";
myHeaders.append("Content-Type", `application/json`);


export async function sendImage(text, imgUrl) {
    try {
        var init = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify({
                content: text,
                embeds: [{
                    type: "image",
                    image: {
                        url: imgUrl
                    }
                }]
            })
        };

        var req = new Request(`${discordWebhook}`, init);

        const result = await fetch(req)
        return result.status
    } catch (exception) {
        console.error(exception);
        return "An error occurred";
    }
}