import React from 'react';

const BonjourMadameContainer = () => {
    return (
        <div>

        </div>
    );
}

export default BonjourMadameContainer;
