import React from "react";
import PropTypes from "prop-types";
import getService from "../../services";
import BonjourMadameContainer from "./BonjourMadameContainer";
import ImagesListContainer from "./ImagesListContainer";

const ImagesContainer = (props) => {
  const { setImageChosen, container } = props;
  const [imagesList, setImagesList] = React.useState([]);
  const [fetched, setFetched] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [currentContainer, setCurrentContainer] = React.useState("");

  React.useEffect(() => {
    const retrieveData = () => {
      setFetched(false);
      const retrieveImages = getService(container);
      retrieveImages(page).then((images) => {
        setFetched(true);
        setImagesList(images);
      });
    };
    if (container !== currentContainer) {
      setPage(1);
      setCurrentContainer(container);
    }
    retrieveData();
  }, [container, page, currentContainer]);

  const getContainer = () => {
    switch (container) {
      case "madame":
        return <BonjourMadameContainer />;
      default:
        return (
          <ImagesListContainer
            fetched={fetched}
            imagesList={imagesList}
            page={page}
            setImageChosen={setImageChosen}
            setPage={setPage}
          />
        );
    }
  };

  return <div className="mx-8 my-3 lg:mx-32 lg:my-14">{getContainer()}</div>;
};

ImagesContainer.propTypes = {
  setImageChosen: PropTypes.func.isRequired,
  container: PropTypes.string.isRequired,
};

export default ImagesContainer;
