import React from "react";
import PropTypes from "prop-types";
import List from "../List";
import Pagination from "../Pagination";
import ContentLoader from "react-content-loader";

const ImagesListContainer = (props) => {
  const { fetched, imagesList, setImageChosen, page, setPage } = props;
  return (
    <>
      {fetched ? (
        <>
          <h2 className="pb-3 mb-3 text-lg text-center lg:text-2xl lg:text-left text-blue-500 font-semibold ">
            Choose an image
          </h2>
          {Array.isArray(imagesList) ? (
            <List
              key={"image-list"}
              imagesList={imagesList}
              setImageChosen={setImageChosen}
            />
          ) : (
            imagesList?.error
          )}
        </>
      ) : (
        <ContentLoader
          width={"100%"}
          viewBox="0 0 800 575"
          className="w-max h-screen"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="12" y="58" rx="2" ry="2" width="150" height="200" />
          <rect x="225" y="57" rx="2" ry="2" width="150" height="200" />
          <rect x="430" y="56" rx="2" ry="2" width="150" height="200" />
          <rect x="640" y="55" rx="2" ry="2" width="150" height="200" />
          <rect x="12" y="283" rx="2" ry="2" width="150" height="200" />
          <rect x="225" y="281" rx="2" ry="2" width="150" height="200" />
          <rect x="430" y="279" rx="2" ry="2" width="150" height="200" />
          <rect x="640" y="277" rx="2" ry="2" width="150" height="200" />
        </ContentLoader>
      )}
      <Pagination key={"pagination"} page={page} setPage={setPage} />
    </>
  );
};

ImagesListContainer.propTypes = {
  fetched: PropTypes.bool.isRequired,
  imagesList: PropTypes.array.isRequired,
  setImageChosen: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
};

export default ImagesListContainer;
