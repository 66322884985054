import React from "react";
import PropTypes from "prop-types";

const Pagination = (props) => {
  const { setPage, page } = props;
  const prevPage = () => {
    setPage(page - 1);
  };
  const nextPage = () => {
    setPage(page + 1);
  };
  return (
    <>
      <h4 className="text-center text-blue-500 text-sm lg:text-lg mt-12 p-2">
        Page
      </h4>
      <div className="flex w-full justify-center items-center gap-4 text-blue-500 text-sm lg:text-lg mb-12">
        <span
          className="pagination-link h-9 w-9 lg:h-12 lg:w-12 hover:bg-blue-500 hover:text-white transition-all"
          onClick={prevPage}
        >
          <i className="fa-light fa-backward"></i>
        </span>
        <span className="font-bold">{page}</span>

        <span
          className="pagination-link h-9 w-9 lg:h-12 lg:w-12  hover:bg-blue-500 hover:text-white transition-all"
          onClick={nextPage}
        >
          <i className="fa-light fa-forward align-sub"></i>
        </span>
      </div>
    </>
  );
};

Pagination.propTypes = {
  setPage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
};

export default Pagination;
