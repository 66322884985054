import createRequest from "../utils";

const apiUrl = "https://api.pexels.com/v1/curated";
const accessKey = "563492ad6f9170000100000191a878a33a3b4249be6b1d51a1b2404f";
var myHeaders = new Headers();
myHeaders.append("Authorization", `${accessKey}`);



export async function fetchImages(pageNbr = 1) {
    const req = await createRequest(`${apiUrl}?page=${pageNbr}&per_page=10`, "GET", myHeaders);
    if (Array.isArray(req?.photos)) {
        return req.photos.map((image) => image.src.original)
    }
    return req
}