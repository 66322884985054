import React from "react";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";
import Messages from "./Messages";
import getService from "../../services";

const Form = (props) => {
  const { imgUrl, setImageChosen } = props;
  const [loading, setLoading] = React.useState(false);
  const [messageType, setMessageType] = React.useState("");
  const [message, setMessage] = React.useState("");
  const sendImage = getService("discord");

  React.useEffect(() => {
    setTimeout(() => {
      setMessageType("");
      setMessage("");
    }, 2000);
  }, [messageType, message]);
  const handleBack = () => {
    setImageChosen(undefined);
  };

  const handleSend = (e) => {
    e.preventDefault();
    e.persist();
    setLoading(true);

    sendImage(e.target.message_content.value, imgUrl).then((statusCode) => {
      setLoading(false);
      e.target.message_content.value = "";
      if (statusCode === 204) {
        setMessage("Message sended successfully");
        return setMessageType("success");
      }
      setMessage("An error ocurred !");
      return setMessageType("error");
    });
  };

  return (
    <>
      <form
        className="flex justify-center gap-4 mt-2 flex-col items-center mx-4"
        onSubmit={handleSend}
      >
        <div className="h-2/4 flex flex-col gap-4">
          <img
            src={imgUrl}
            alt="Your choice"
            className="image-chose rounded-md object-contain lg:h-[500px]"
          />
          <textarea
            name="message_content"
            className="p-5 text-blue-500 border-2 rounded-md text-lg "
            placeholder="Your message"
            required
          />
        </div>
        {message && (
          <CSSTransition
            in={message}
            timeout={2000}
            classNames={{
              appearActive: "animate__animated animate__fadeIn",
              exit: "animate__animated animate__fadeOut",
            }}
            appear
          >
            <div className="w-fit mx-auto mb-8  lg:mb-14 lg:mt-1 rounded-md text-center">
              <Messages type={messageType} message={message} />
            </div>
          </CSSTransition>
        )}
        <div className="flex justify-center gap-4 mb-12">
          <button onClick={handleBack} className="action-btn hover:scale-105">
            <i className="fa-light fa-arrow-left align-top text-xl mr-2"></i>
            Back
          </button>

          <button type="submit" className="action-btn hover:scale-105">
            Send
            {loading ? (
              <i className="fa-light fa-spinner-third fa-spin align-top text-xl ml-2"></i>
            ) : (
              <i className="fa-light fa-paper-plane-top  align-top text-xl ml-2"></i>
            )}
          </button>
        </div>
      </form>
    </>
  );
};

Form.propTypes = {
  imgUrl: PropTypes.string.isRequired,
  setImageChosen: PropTypes.func.isRequired,
};

export default Form;
