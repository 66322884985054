import { fetchImages as rule34Fetch } from "./Rule34"
import { fetchImages as unsplashFetch } from "./Unsplash"
import { fetchImages as pexelFetch } from "./Pexel"
import { sendImage } from "./Discord"
import { getTodayImage } from "./BonjourMadame";

export default function getService(serviceName) {
    switch (serviceName) {
        case "nsfw":
            return rule34Fetch;
        case "unsplash":
            return unsplashFetch;
        case "discord":
            return sendImage;
        case "pexel":
            return pexelFetch;
        case "madame":
            return getTodayImage;
        default:
            break;
    }
}