import React from "react";
import PropTypes from "prop-types";
const Messages = (props) => {
  const { type, message } = props;
  const classes = {
    success: "text-blue-500 p-4 bg-blue-200",
    error: "text-red-500 p-4 bg-red-200",
  };
  return (
    <p className={`text-center rounded-lg ${classes[type]} `}>{message}</p>
  );
};

Messages.propTypes = {
  type: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default Messages;
