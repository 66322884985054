import React from "react";
import PropTypes from "prop-types";
import ImageCard from "../ImageCard";

const List = (props) => {
  const { imagesList, setImageChosen } = props;
  return (
    <ul className="flex flex-wrap gap-8 min-h-screen justify-evenly">
      {imagesList.map((image, index) => (
        <ImageCard imgUrl={image} setImageChosen={setImageChosen} />
      ))}
    </ul>
  );
};

List.propTypes = {
  imagesList: PropTypes.array.isRequired,
  setImageChosen: PropTypes.func.isRequired,
};

export default List;
