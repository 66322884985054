import createRequest from "../utils";

const apiUrl = "https://r34-json-api.herokuapp.com/";
var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json")


export async function fetchImages(pageNbr = 1) {
    const req = await createRequest(`${apiUrl}posts?limit=10&pid=1${pageNbr}`, "GET", myHeaders);
    if (Array.isArray(req)) {
        return req.map((image) => image.file_url)
    }
    return req;
}