import React from "react";

const Footer = () => {
  return (
    <div className="fixed bottom-0 w-full flex justify-center py-2 bg-white shadow text-gray-500 text-xs">
      <a href="https://www.pexels.com">Photos provided by Pexels</a>
    </div>
  );
};

export default Footer;
