import React from "react";
import PropTypes from "prop-types";

const NavLink = (props) => {
  const {
    id,
    text,
    setPage,
    current,
    textColor,
    borderColor,
    hoverBorderColor,
    hoverTextColor,
  } = props;
  const handleClick = () => setPage(id);
  return (
    <span
      className={`nav-link ${hoverTextColor} ${hoverBorderColor} ${
        current === id ? `${textColor} ${borderColor}` : "border-transparent"
      }`}
      onClick={handleClick}
    >
      {text}
    </span>
  );
};

NavLink.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  setPage: PropTypes.func.isRequired,
  current: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  borderColor: PropTypes.string.isRequired,
  hoverTextColor: PropTypes.string.isRequired,
  hoverBorderColor: PropTypes.string.isRequired,
};

export default NavLink;
