import createRequest from "../utils";

const apiUrl = "https://api.unsplash.com/";
const accessKey = "CUvk4_PontnyCawzbUT5-TaaVHxB2TDKiJ4L5XyRbAM";
var myHeaders = new Headers();
myHeaders.append("Accept-Version", "v1");
myHeaders.append("Authorization", `Client-ID ${accessKey}`);



export async function fetchImages(pageNbr = 1) {
    const req = await createRequest(`${apiUrl}photos?page=${pageNbr}`, "GET", myHeaders);
    if (Array.isArray(req)) {
        return req.map((image) => image.urls.full)
    }
    return req
}