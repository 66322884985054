import React from 'react';
import './App.css';
import Form from './modules/Form';
import ImagesContainer from './modules/ImagesContainer';
import Footer from './components/Footer';
import Header from './components/Header';
import { CSSTransition } from 'react-transition-group';

function App() {
  const [imageChosen, setImageChosen] = React.useState(undefined);
  const [currentPage, setCurrentPage] = React.useState('unsplash')
  const links = [
    { id: 'unsplash', text: 'Unsplash', hidden: false, textColor: "text-blue-500", borderColor: "border-blue-500", hoverTextColor: "hover:text-blue-500", hoverBorderColor: "hover:border-blue-500" },
    { id: 'pexel', text: 'Pexel', hidden: false, textColor: "text-violet-500", borderColor: "border-violet-500", hoverTextColor: "hover:text-violet-500", hoverBorderColor: "hover:border-violet-500" },
    { id: 'madame', text: 'Bonjour Madame', textColor: "", hidden: true }
  ];
  return (
    <>
      <Header currentPage={currentPage} imageChosen={imageChosen} links={links} setCurrentPage={setCurrentPage} />

      {!imageChosen ?
        <ImagesContainer setImageChosen={setImageChosen} container={currentPage} />
        :
        <CSSTransition
          in={typeof imageChosen !== 'undefined'}
          key={imageChosen}
          timeout={10000}
          addEndListener={(node, done) => node.addEventListener("transitionend", done, false)}
          classNames={{
            appearActive: "animate__animated animate__bounceIn",
          }}
          appear
        >
          <Form imgUrl={imageChosen} setImageChosen={setImageChosen} />
        </CSSTransition>
      }


      <Footer />


    </>
  );
}

export default App;
