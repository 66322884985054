import React from "react";
import PropTypes from "prop-types";
import NavLink from "../NavLink";
import logo from "../../assets/iso_1664.jpeg";

const Header = (props) => {
  const { imageChosen, links, currentPage, setCurrentPage } = props;
  return (
    <div className="w-full mt-3 pb-3 flex flex-col items-center justify-center relative h-[100px] shadow-md gap-4 ">
      <img
        src={logo}
        alt="Logo"
        className="invisible w-[50px] h-[50px] lg:w-[100px] lg:h-[100px] left-6 absolute lg:visible "
      />

      {!imageChosen && (
        <div className="grid grid-flow-col text-gray-400 gap-2">
          {links.map(
            ({
              id,
              text,
              hidden,
              textColor,
              borderColor,
              hoverTextColor,
              hoverBorderColor,
            }) =>
              !hidden && (
                <NavLink
                  id={id}
                  key={id}
                  text={text}
                  current={currentPage}
                  textColor={textColor}
                  setPage={setCurrentPage}
                  borderColor={borderColor}
                  hoverTextColor={hoverTextColor}
                  hoverBorderColor={hoverBorderColor}
                />
              )
          )}
        </div>
      )}
    </div>
  );
};

Header.propTypes = {
  imageChosen: PropTypes.string,
  links: PropTypes.array.isRequired,
  currentPage: PropTypes.string.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
};

export default Header;
