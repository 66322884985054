export default async function createRequest(apiUrl, method, headers) {
    try {
        var init = {
            method: method,
            headers
        };

        var req = new Request(`${apiUrl}`, init);

        const result = await fetch(req)
        const json = await result.json();
        return json;
    } catch (exception) {
        console.error(exception);
        return "An error occured " + exception;
    }


}